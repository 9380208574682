<template>
    <div v-if="type.substring(0, 8) == 'checkbox'" :class="{'d-flex': label}">
      <template v-if="type == 'checkbox'">
        <input v-model="valueData" type="checkbox" class="form-check-input optional" :id="id" :true-value="1" :false-value="0" @click="$emit('onclick', $event)" :disabled="disabled" @change="$emit('onchange', $event)" />
      </template>
      <template v-else-if="type == 'checkbox-with-value'">
        <input v-model="valueData" type="checkbox" class="form-check-input optional" :id="id" :value="content" @click="$emit('onclick', $event)" :name="nameField" @change="$emit('onchange', $event)" :disabled="disabled" />
      </template>
      <template v-else-if="type == 'checkbox-with-values'">
        <input v-model="valueData" type="checkbox" class="form-check-input optional" :id="id" :true-value="options[0]" :false-value="options[1]" @click="$emit('onclick', $event)" :name="nameField" @change="$emit('onchange', $event)" :disabled="disabled" />
      </template>
      <label v-if="label" :for="this.id" class="pt-0" :class="(required ? 'required-label' : '') + 'auto-check-label-after mt-0 ' + (disabled ? ' text-muted' : '')">{{this.label}}</label>
    </div>
    <template v-else-if="type == 'icon' && infoIconText">
      <a class="fa fa-info-circle pt-1" tabindex="0" :id="id + '_popover'" data-bs-toggle="popover" :data-bs-content="infoIconText" @click="setPopover"></a>
    </template>
    <div v-else-if="type == 'label'" class="d-flex" :class="(required ? 'required' : '') + (' col-sm-auto')">
      <label :for="this.id" >{{this.label}}</label>
      <div class="d-flex ps-2 pt-1" v-if="infoIconText">
          <a class="fa fa-info-circle pt-1" tabindex="0" :id="id + '_popover'" data-bs-toggle="popover" :data-bs-content="infoIconText" @click="setPopover"></a>
      </div>
    </div>
    <div v-else :class="label && type != 'download' ? ('row mb-2') : ''">
        <label v-if="labelAbove" :for="this.id" class="col-form-label col-form-label-sm form-label-above" :class="(required ? 'required-label' : '')">{{this.labelAbove}}</label>
        <div v-if="label && type != 'download'" class="d-flex" :class="(required ? 'required' : '') + (' col-sm-' + sizeLabel)">
          <label :for="this.id" >{{this.label}}</label>
          <div class="d-flex ps-2 pt-1" v-if="infoIconText">
              <a class="fa fa-info-circle pt-1" tabindex="0" :id="id + '_popover'" data-bs-toggle="popover" :data-bs-content="infoIconText" @click="setPopover"></a>
          </div>
        </div>

        <div v-if="type == 'buttongroup'" class="btn-group btn-group-sm" :class="' col-sm-' + sizeItem + (required ? ' required' : '')">
            <template v-for="item in options" :key="item.id">
                <input :id="this.id + '_' + item.id" type="radio" :name="this.id" class="btn-check" :value="item.id" @input="handleInput($event); $emit('onchange', $event)" @click="$emit('onclick', $event)" :checked="item.id == modelValue ? true : null" :disabled="item.disabled || disabled" />
                <label :for="this.id + '_' + item.id" :data-disabled="item.disabled || disabled"  class="btn btn-outline-primary">{{item.name}}</label>
            </template>
        </div>
        <div v-else-if="type == 'radiogroup'" class="btn-group btn-group-sm d-flex" :class="' col-sm-' + sizeItem + (required ? ' required' : '')">
            <div :class="'d-flex form-check ' + (extraOption ? ('width-' + options.length) : 'flex-grow-1')" v-for="item in options" :key="item.id">
                <input :id="this.id + '_' + item.id" type="radio" class="form-check-input mt-2" :name="this.id" :disabled="disabled || item.disabled"
                       :value="item.id" @input="handleInput($event); $emit('onchange', $event)" :checked="item.id == modelValue ? true : null" />
                &ensp;
                <label :for="this.id + '_' + item.id" class="form-check-label col-form-label-sm">{{item.name}}</label>
            </div>
        </div>
        <div v-else-if="type == 'radiogroup-status'" class="btn-group btn-group-sm d-flex col-sm-12">
            <div class="d-flex flex-grow-1 form-check" v-for="item in options" :key="item.id">
                <input :id="this.id + '_' + item.id" type="radio" class="form-check-input mt-2" :name="this.id" :disabled="item.disabled || disabled"
                       :value="item.id" @input="handleInput($event); $emit('onchange', $event)" @click="$emit('onclick', $event)" :checked="item.id == modelValue ? true : null" />
                &ensp;
                <label :for="this.id + '_' + item.id" class="form-check-label col-form-label-sm">{{item.name}}</label>
            </div>
        </div>

        <div v-else :class="(label && type != 'download') ? ('col-sm-' + sizeItem) : ''">
            
            <template v-if="type== 'livesearch'">

              <SimpleTypeahead
                :id="this.id"
                :items="this.livesearch.list"
                :minInputLength="1"
                :modelValue="modelValue"
                @input="beforeInput"
                :itemProjection="itemProjection"
                @selectItem="selectItem" @onInput="onInput" @onBlur="onBlur" 
                :content="this.content"
                :required="required"
                :disabled="disabled"
                :extraOption="extraOption"
                data-1p-ignore
              />
              
            </template>

            <template v-else-if="type== 'livesearch-list'">

              <SimpleTypeahead
                :id="this.id"
                :items="this.livesearch.list"
                :minInputLength="1"
                :isForList="true"
                :modelValue="modelValue"
                @input="beforeInput"
                :itemProjection="itemProjection"
                @selectItem="selectListItem" @onInput="onInput" @onBlur="onBlur" 
                :content="this.content"
                :disabled="disabled"
              />
              
              <div class="d-flex flex-column flex-wrap mt-2">
                <div v-for="(item,itemIndex) in modelValue" :key="itemIndex" class="w-auto">
                  <button type="button" class="btn btn-sm text-danger p-0" title="Click to delete" v-show="!disabled" @click="deleteListItem(itemIndex)">
                    <i class="far fa-close"></i>
                  </button>
                  &ensp;
                  <span> {{ item[nameField] }} </span>   
                </div> 
              </div>
              
            </template>

            <template v-else-if="type == 'radio'">
              <input v-model="valueData" type="radio" class="form-check-input optional" :value="idField" :id="id" :name="nameField" @click="$emit('onclick', $event)" :disabled="disabled" />
            </template>

            <template v-else-if="type == 'slider'">
              <div :class="{'text-end' : extraOption != 'align-left'}">
                <label class="bs-switch" :class="{'compact' : extraOption == 'compact', 'thin' : extraOption == 'thin'}">
                  <input v-model="valueData" type="checkbox" :id="id" :true-value="options[0]" :false-value="options[1]" @change="$emit('onchange', $event)" :disabled="disabled" />
                  <span class="bs-slider"></span>
                </label>
              </div>
            </template>

            <template v-else-if="type == 'phone'">
              <vue-tel-input :id="id" v-model="valueData" mode="international" :defaultCountry="phoneDefaultCountry ? phoneDefaultCountry : 'NL'" :class="required ? 'required' : 'optional'" 
                             @on-input="(n, o) => setTelInput(n, o, id)" @blur="checkPhone(id)" :inputOptions="telInputOptions" :disabled="disabled" ></vue-tel-input>
              <p class="text-danger"><i class="fa fa-warning"></i>&ensp;Ongeldig telefoonnummer</p>
            </template>

            <template v-else-if="type == 'text-voyage'">
              <input :id="id" v-model="valueData" class="form-control form-control-sm" @change="$emit('onchange', $event)" @focus="setFocusVoyage" :required="required" />
            </template>

            <template v-else-if="type == 'text-with-tags'">
              <div class="input-group">
                <span v-if="startTag" class="input-group-text py-0">{{startTag}}</span>
                <input :id="id" v-model="valueData" class="form-control form-control-sm" @change="$emit('onchange', $event)" :required="required" />
                <span v-if="endTag" class="input-group-text text-small px-2 py-0">{{endTag}}</span>
              </div>
            </template>

            <template v-else-if="type == 'number-with-tags'">
              <div class="input-group">
                <span v-if="startTag">{{startTag}}</span>
                <input :id="id" v-model="tempInput" class="form-control form-control-sm measure-field number-format" @keydown="preventNonNumbers($event, true, false)" @input="handleInput" :disabled="disabled"
                   :required="required" @change="preValidate($event)" @blur="setCurrencyField($event, false)" :placeholder="placeholder" />
                <span v-if="endTag" class="input-group-text text-small px-2 py-0">{{endTag}}</span>
              </div>
            </template>

            <template v-else-if="type == 'currency-and-unit'">
              <div class="input-group currency-and-unit">
                <select v-model="valueData[(inputGroupType ? (inputGroupType+'_') : '')+calcUnitFields[0]]" class="form-select form-select-sm" @change="$emit('onchange', $event)" v-if="valueData[(inputGroupType ? (inputGroupType+'_') : '')+calcUnitFields[1]] != 5" :disabled="disabled">
                  <option v-for="currency in options.currencies" :key="currency.id" :value="currency.id">
                    {{currency.code}}
                  </option>
                </select>
                <input v-model="tempInput" class="form-control form-control-sm" @change="preValidate($event)" @keydown="preventNonNumbers($event, true, true); $emit('oninput', $event)" :id="id" :disabled="disabled"
                      @focus="currencyInputShow = true" @blur="setCurrencyField($event, false); $emit('onblur', $event)" :class="{'tabbable-hide': !currencyInputShow, 'optional': !required}" :placeholder="placeholder" />
                <div v-show="!currencyInputShow" class="form-control form-control-sm curr-preview" :class="{'ca-disabled': disabled}" @click="setCurrencyField($event, true)">
                  {{(valueData[inputGroupType] === null || valueData[inputGroupType] === '' ? '' : formatCurrency(placeholder ? placeholder : (inputGroupType ? valueData[inputGroupType] : valueData.amount)))}}
                </div>
                <select v-model="valueData[(inputGroupType ? (inputGroupType+'_') : '')+calcUnitFields[1]]" class="form-select form-select-sm unit-select" @change="$emit('onchange', $event); preValidate($event)" :disabled="disabled">
                  <option value=''>Selecteer...</option>
                  <option v-for="calculation_unit in options.calculation_units" :key="calculation_unit.id"
                    :value="calculation_unit.id">
                    {{calculation_unit.name}}
                  </option>
                </select>
              </div>
            </template>

            <template v-else-if="type == 'currency-and-amount'">
              <div class="input-group currency-field" :class="{'normal-font': extraOption}">
                <span v-if="startTag">{{startTag}}</span>
                <input :class="{'tabbable-hide': !currencyInputShow, 'optional': !required}" v-model="tempInput" class="form-control number-format tms" @change="preValidate($event)" 
                      @keydown="preventNonNumbers($event, true, true); $emit('oninput', $event)" @focus="currencyInputShow = true" @blur="setCurrencyField($event, false)" :disabled="disabled" />
                <div class="form-control number-format curr-preview" :class="{'ca-disabled': disabled, 'tabbable-hide': currencyInputShow}" @click="setCurrencyField($event, true)">
                  {{(valueData === undefined || valueData === null || valueData === '' ? '' : formatCurrency(valueData))}}
                </div>
              </div>
            </template>

            <template v-else-if="type == 'download'"> 
              <button :id="id" type="button" class="btn" @click="onDownloadButtonClick($event, true)" :disabled="disabled" 
                      :class="{'w-100': options && options.fullWidth, 'btn-sm': options && options.small, 'mb-1': options && options.margin, 'btn-unidark': options && options.send, 'btn-unidark ps-4': !options || !options.send}">
                <span>{{label}}</span><i class="far ms-3" :class="endTag ? endTag : 'fa-file-pdf'"></i><i class="fa fa-spinner fa-spin d-none ms-3"></i>
              </button>
            </template>

            <template v-else-if="type == 'textarea'"> 
              <textarea @input="handleInput" wrap="off" :value="modelValue" :rows="options ? options : false" class="form-control form-control-sm" :class="{'text-uppercase': extraOption}" :id="id" :required="required" :disabled="disabled" @change="textfieldHandleInput" @blur="$emit('onblur', $event)"></textarea>
            </template>

            <template v-else-if="type == 'textarea-bl'"> 
              <textarea @input="handleInput" wrap="off" :value="modelValue" rows="5" class="form-control form-control-sm text-uppercase" style="resize: none; height: 110px" :id="id" :required="required" :disabled="disabled" @change="textfieldHandleInput" @blur="onBLFieldBlur" @keydown="maxFiveRows"></textarea>
            </template>

            <template v-else-if="type == 'textarea-chassis-number'"> 
              <textarea @input="handleInput" wrap="off" :value="modelValue" class="form-control form-control-sm" :id="id" :required="required" :disabled="disabled" @keydown="licensePlateAlterInput" @change="textfieldHandleInput($event, false)"></textarea>
            </template>

            <div v-else-if="type == 'select'" class="col-select w-100"> 
              <!--<select v-model="valueData" :data-value="valueData" :class="'form-select form-select-sm' + ((content == 'narrow') ? ' narrow' : '')" :id="id" :disabled="disabled" 
                     @change="$emit('onchange', $event, options, options.find ? options.find(opt => opt[idField] == $event.target.value) : null); preValidate($event)" :required="required">
                <option v-if="selectDefault" :value="null">{{ placeholder ? placeholder : 'Selecteer...' }}</option>
                <option v-for="option, index in options" :key="index" :value="idField === 'index' ? index : option[idField]" :disabled="disabledOptions ? disabledOptions(option, valueData, extraOption) : false">
                    {{option[nameField]}}
                </option>
              </select>-->
                <select v-model="valueData" @change="$emit('onchange', $event)" :class="{'optional': !required}" :multiple="extraOption == 'multiple'" :disabled="disabled">
                    <option :value="null" v-if="placeholder">{{placeholder}}</option>
                    <option v-for="option in options" :key="option[idField]" :value="option[idField]" :disabled="option.disabled">{{option[nameField]}}</option>
                </select>
            </div>

            <template v-else-if="type == 'select-multiple-fields'">
              <div v-for="(item, commIndex) in valueData" :key="commIndex">
                <select v-model="item.id" class="form-select form-select-sm commodity-name mt-2 w-85 d-inline" :id="id+commIndex" @change="$emit('onchange')" :disabled="disabled">
                  <option value=''>Selecteer...</option>
                  <option v-for="option in options" :key="option.id" :value="option.id" :disabled="checkIfAlreadyChosen(id+commIndex, option.id, option)">
                    {{option[nameField]}}
                  </option>
                </select>
                <button type="button" class="btn text-danger p-0 ms-3" @click="deleteListItem(commIndex)" v-show="!disabled"><i class="far fa-close"></i></button>
              </div>
              <a class="btn btn-sm btn-primary add-comm-button" title="Click to add item" v-show="valueData.length < options.length && !disabled" @click="addItem(valueData)">
                <span class="fa fa-plus"></span>
              </a>
              <div v-if="valueData.length == 0" class="no-items"></div>
            </template>

            <template v-else-if="type == 'select-list'"> 
              <select class="form-select form-select-sm stype-column" :id="id" :disabled="disabled" @change="$emit('add', $event)">
                <option v-if="selectDefault" value="">{{ placeholder ? placeholder : 'Selecteer...' }}</option>
                <option v-for="option, optionIndex in options" :key="option.id" :value="optionIndex">
                    {{option[nameField]}}
                </option>
              </select>
              <div class="d-flex flex-column flex-wrap mt-2">
                <div v-for="(item,itemIndex) in modelValue" :key="itemIndex" class="w-auto">
                  <button type="button" class="btn btn-sm text-danger p-0" title="Click to delete" @click="$emit('delete', item, itemIndex)">
                    <i class="far fa-close"></i>
                  </button>
                  &ensp;
                  <span> {{ item[nameField] }} </span>
                </div> 
              </div>
            </template>
            <input v-else-if="type == 'number-range'" @input="handleInput" @change="preValidate($event)" :value="modelValue" type="number" :id="id" :required="required" 
                  :disabled="disabled" :placeholder="placeholder" :min="options.min" :max="options.max" class="form-control form-control-sm number-format number-range" />
            <input v-else-if="type == 'number'" @keydown="preventNonNumbers($event, allowCommas, allowNegative)" @input="handleInput" @change="preValidate($event)" :value="tempInput" class="form-control form-control-sm number-format" 
                   :id="id" :required="required" :disabled="disabled" :placeholder="placeholder" @blur="setCurrencyField($event, false)" />
            <input v-else @input="handleInput" @change="preValidate($event); $emit('onchange', $event)" :value="modelValue" :class="'w-100 ' + (required ? '' : 'optional')" :id="id" :required="required" 
                   :type="type" :disabled="disabled" :placeholder="placeholder" class="form-control form-control-sm" />
        </div>
    </div>
</template>

<script>
import SimpleTypeahead from '@/components/vue3-simple-typeahead.vue';
import { VueTelInput } from 'vue-tel-input';
import { ObjectUtils } from 'primevue/utils';
import { getCountryCallingCode } from "libphonenumber-js";
import { Popover } from 'bootstrap';
import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css';
import 'vue-tel-input/dist/vue-tel-input.css';

// import {debounce} from 'lodash';

export default {
  name: "FormItem",
  components: {
      // Datepicker,
      VueTelInput,
      SimpleTypeahead
  },
  props: {
        'modelValue': {
          default(){ return null }
        },
        'id': {}, 
        'label': {}, 
        'labelAbove': {}, 
        'required': {
          default(){ return null }
        },
        'type': {
            default(){ return 'text' }
        },
        'selectDefault': {
            default(){ return true }
        },
        'options': {},
        'inputGroupType': {},
        'startTag': {},
        'endTag': {},
        'service': null,
        'extraOption': null,
        'extraOptions': [],
        'infoIconText': null,
        'content': {},
        'functionArgs': {
          default(){ return [] }
        },
        'idField': {
          default(){ return 'id' }
        },
        'nameField': {
          default(){ return 'name' }
        },
        'phoneDefaultCountry': {
          default(){ return 'NL' }
        },
        'calcUnitFields': {
          default(){ return ['currency_id','calculation_unit_id'] }
        },
        'sizeLabel': {
          default(){ return '4' }
        },
        'sizeItem': {
          default(){ return '8' }
        },
        'placeholder': {
          default(){ return null }
        },
        'allowCommas': {
          default(){ return true }
        },
        'allowNegative': {
          default(){ return false }
        },
        'disabled': {
          default(){ return null }
        },
        'disabledOptions': {
          default(){ return false }
        },
        'value': {}
    },
  data () {
    return {
      livesearch: {
        input: '',
				selection: null,
        list: [],
        listFiltered: [],
        options: {
          placeholder: 'Select...',
          minInputLength: 1,
        },
        lastsearch: null
      },
      selectList : [],
      telValidity: null,
      currencyInputShow: false,
      tempInput: '',
      infoOpen: false,
      telInputOptions: {
        autofocus: false,
        maxlength: 25,
        name: 'telephone',
        placeholder: this.phoneDefaultCountry ? ('+'+getCountryCallingCode(this.phoneDefaultCountry)) : '',
        readonly: false,
        required: this.required,
        tabindex: 0,
        type: 'tel',
      }
    }
  },
  watch: {
      modelValue: {
          immediate: true,
          handler() {
              this.setCurrencyFieldNoEvent();
          }
      }
  },
  computed: {
      valueData: {
          get() { return (this.type == 'phone' && this.modelValue === null) ? '' : this.modelValue },
          set(valueData) {this.$emit('update:modelValue', valueData)}
      },
      contentData: {
        get() { return this.content },
        set(contentData) {this.$emit('update:content', contentData)}
      }
  },
  created() {
		this.listFiltered = this.list;
    this.selectList = this.modelValue;
	},
	methods: {
    handleInput (e) {
      let value = e.target.value;
      if(value === 'true') value = true;
      if(value === 'false') value = false;
      if(e.target.tagName != "TEXTAREA"){
        this.$emit('update:modelValue', value);
        this.preValidate(e);
      }
      this.$emit('oninput', e);
    },
    textfieldHandleInput(e, prevalidate = true){
      if(prevalidate) this.preValidate(e);
      this.$emit('update:modelValue', e.target.value);
      this.$emit('onchange', e);
    },

    //Livesearch functions
    beforeInput: function(e){
      if(this.required != null)
        e.target.classList.remove('is-invalid');
      const minLength = this.service ? 3 : 1;
      if(this.lastsearch == this.livesearch.input || !e.target.value || e.target.value.length < minLength || e.inputType == 'deleteContentBackward') return;
      this.lastsearch = this.livesearch.input;
      if(this.type != 'livesearch-list'){
        //this.$emit('update:modelValue', null);
        if(e.target.value == ''){
          this.$nextTick(() => this.$emit('onchange', e));
        }
      }

      if(!this.service){
        this.livesearch.list = this.options;
      }
      else{
        this.service.livesearch(this.livesearch.input, this.nameField, ['id'], this.extraOptions).then(response => {
          this.livesearch.list = response.data.data;
          if(response.data.data[0] && response.data.data[0].relation){
            this.livesearch.list.forEach(item => item.name = item.relation.name);
          }
        }).catch(error => {
            console.log('error', error)
        });
      }
    },

    itemProjection(item) {
      
      if(this.nameField.includes('.')) {
        const value = ObjectUtils.resolveFieldData(item, this.nameField);
        return value ? value : '';
      } 

      return item[this.nameField] ? item[this.nameField] : '';

    },
    selectItem(item) {
			this.livesearch.selection = item;
      if(item){
        this.$emit('update:modelValue', item[this.idField]);
        this.$emit('onchange', item[this.idField], item[this.nameField], item);
        this.contentData = this.itemProjection(item);
      }
      else{
        this.$emit('update:modelValue', '');
        this.$emit('onchange', null, '');
        this.contentData = null;
      }
		},
    selectListItem(item) {
      this.livesearch.selection = null;
      if(!item) return;
      console.log(item, this.idField);
      //Check if not already in the list
      let foundDuplicate = false;
      this.modelValue.forEach(carr => {
          if(carr[this.idField] == item[this.idField] || carr[this.nameField] == item[this.nameField]) {
              foundDuplicate = true;
              return;
          }
      });
      if(foundDuplicate) return;
      let modelValue = this.modelValue;
			modelValue.push(item);
      //Sort
      if(modelValue.length > 1){
          modelValue.sort(function(a, b) {
              const nameA = a.name.toUpperCase();
              const nameB = b.name.toUpperCase();
              if (nameA < nameB) {
                  return -1;
              }
              if (nameA > nameB) {
                  return 1;
              }

              return 0;
          });
      }

      this.$emit('update:modelValue', modelValue);
      this.$emit('add', item[this.idField], item[this.nameField], item);
      this.contentData = this.itemProjection(item);
		},
    addItem(list){
      list.push({'id': ''});
      this.$emit('update:modelValue', list);
      this.$emit('add', list);
    },
    deleteListItem(itemIndex){
      const list = (this.type === 'livesearch-list' || this.type == 'select-multiple-fields') ? this.modelValue : this.selectList;
      const carriers = list[itemIndex].carriers ? list[itemIndex].carriers : [];
      const item = JSON.parse(JSON.stringify(list[itemIndex]));
      list.splice(itemIndex, 1);
      this.$emit('update:modelValue', list);
      this.$emit('delete', itemIndex, carriers, item);
    },
		onInput(event) {
			this.livesearch.selection = null;
			this.livesearch.input = event.input;
			this.livesearch.listFiltered = event.items;
		},
		onBlur(event) {
			this.livesearch.input = event.input;
			this.livesearch.listFiltered = event.items;

      this.livesearch.selection
		},
    setFocusVoyage(e){
      window.setTimeout(() => {
        e.target.selectionStart = e.target.value.length;
      }, 1);
    },
    setTelInput(n, o, id){
      this.$emit('oninput', n);
      this.telValidity = o.valid;
      if(this.telValidity === true){
        const telInput = document.getElementById(id);
        telInput.classList.remove('is-invalid');
      }
    },
    checkPhone(id){
      if(this.telValidity === false){
        const telInput = document.getElementById(id);
        telInput.classList.add('is-invalid');
      }
    },
    preValidate(e){
      if(this.type == 'livesearch'){
        if(this.modelValue)
          e.target.classList.remove('is-invalid');
        else
          e.target.classList.add('is-invalid');
      }
      else if(this.type == 'number-range'){
        const elsToCheck = document.querySelectorAll('.number-range');
        elsToCheck.forEach(el => {
          const value = Number(el.value);
          const min = Number(el.min);
          const max = Number(el.max);
          if((!this.modelValue && el.required) || (this.modelValue && value >= min && value <= max))
            el.classList.remove('is-invalid');
          else
            el.classList.add('is-invalid');
        });
      }
      else{
        if(e.target.value)
          e.target.classList.remove('is-invalid');
        else if(e.target.required)
          e.target.classList.add('is-invalid');
      }
    },
    checkIfAlreadyChosen(elId, optionValue, commodity){
      let comms = [];
      const select = document.getElementById(elId);
      if(select != null && select.value === optionValue) return true;
      if(this.extraOptions){
          this.extraOptions.forEach(tscc => {
            comms.push(tscc.id);
          });
          return comms.includes(commodity.id);
      }
      else{
          this.content.forEach(tsc => {
          tsc.commodities.forEach(tscc => {
            comms.push(tscc.id);
          })
        })
      }
      return comms.includes(commodity.id);
    },
    setCurrencyField(e, setactive){
      if(this.disabled)
        return;
      this.currencyInputShow = setactive;
      if(setactive){
        if(this.type == 'currency-and-amount'){
          const value = this.valueData;
          this.tempInput = value === null ? '' : (value + '').replaceAll('.', ',');
        }
        else if(this.type == 'currency-and-unit'){
          const value = this.valueData[this.inputGroupType ? this.inputGroupType : 'amount'];
          this.tempInput = value === null ? '' : (value + '').replaceAll('.', ',');
        }
        else{
          this.tempInput = this.valueData === null ? '' : (this.valueData + '').replaceAll('.', ',');
          return;
        }
        this.$nextTick(() => e.target.previousElementSibling.focus());
      }
      else{
        let newValue = 0;
        if(this.type == 'currency-and-amount'){
          newValue = this.tempInput === '' ? null : this.tempInput.replaceAll(',', '.');
        }
        /*else if(this.type == 'currency-and-unit'){
          this.valueData[this.inputGroupType ? this.inputGroupType : 'amount'] = this.tempInput === '' ? null : Number(this.tempInput.replaceAll(',', '.'));
        }*/
        else{
          newValue = this.tempInput === '' ? null : this.tempInput.replaceAll(',', '.');
        }
        this.valueData = newValue;
        this.$emit('onchange', e);
        this.$parent.$forceUpdate();
      }
    },
    setCurrencyFieldNoEvent(){
      if(this.type == 'currency-and-amount'){
        const value = this.valueData;
        this.tempInput = value === null ? '' : (value + '').replaceAll('.', ',');
        if(value && typeof value == "number") this.valueData = Math.floor(value * 100) / 100;
      }
      else if(this.type == 'currency-and-unit'){
        const value = this.valueData[this.inputGroupType ? this.inputGroupType : 'amount'];
        this.tempInput = value === null ? '' : (value + '').replaceAll('.', ',');
        if(value && typeof value == "number") this.valueData[this.inputGroupType ? this.inputGroupType : 'amount'] = Math.floor(value * 100) / 100;
      }
      else if(this.type == 'number-with-tags' || this.type == 'number'){
        this.tempInput = this.valueData === null ? '' : (this.valueData + '').replaceAll('.', ',');
      }
    },
    maxFiveRows(e){
      if(e.key == "Enter" && e.target.value.split('\n').length > 4){
        e.preventDefault();
      }
    },
    setPopover(e){
      if(this.infoOpen){
        this.infoOpen = false;
        e.target.blur();
      }
      else if(document.activeElement.id == e.target.id){
        this.infoOpen = true;
      }
    },
    onBLFieldBlur(e){
      this.valueData = this.valueData.toUpperCase();
      this.$emit('onblur', e);
    },
    onDPOpen(){
      if(window.lastDateInput && !this.valueData){
        this.$nextTick(() => this.$refs.datepicker.updateInternalModelValue(new Date(window.lastDateInput)));
      }
    },
    onDPClosed(e){
      if(e != this.valueData){
        this.$emit('onchange', e);
        this.$nextTick(() => window.lastDateInput = this.valueData);
      }
    },
    async onDownloadButtonClick(e, real = false) {
      if(!this.service || !real) return;
      const pdfButton = e.target.tagName == "BUTTON" ? e.target : e.target.parentNode;
      pdfButton.disabled = true;
      pdfButton.childNodes[1].classList.add('d-none');
      pdfButton.childNodes[2].classList.remove('d-none');
      await this.service(this.functionArgs);
      pdfButton.disabled = false;
      pdfButton.childNodes[1].classList.remove('d-none');
      pdfButton.childNodes[2].classList.add('d-none');
    }
  },
  mounted(){
    this.setCurrencyFieldNoEvent();

    if(this.infoIconText){
      const popoverTrigger = document.getElementById(this.id + '_popover');
      console.log(popoverTrigger);
      if(popoverTrigger)
        new Popover(popoverTrigger, { trigger: 'focus', html: true });
    }
  }
}
</script>
<style scoped>
.input-group select:not(.w-33){
    width: 25%;
    background-color: #ddd;
}
.text-small{
  font-size: 0.75rem
}
.w-67{
  width: calc(min(100% - 51px, 65%));
  flex: unset;
  margin: 0 !important;
  border-radius: .2rem 0 0 .2rem !important;
  cursor: text
}
.w-33{
  width: calc(max(33%, 47px));
  padding-right: .5rem;
  padding-left: 4%;
  font-size: .75rem;
  background-position: 92%;
  background-color: #ddd;
  flex: unset;
  margin: 0 !important
}
input.is-invalid + .w-67{
  border: 1px solid red
}
.ca-disabled{
  background-color: #e9ecef;
  cursor: default !important
}

select[multiple]{
  min-height: 8rem;
}

.input-group{
  break-inside: avoid-column;
}

/* The switch - the box around the slider */
.bs-switch {
    position: relative;
    display: inline-block;
    width: 55px;
    height: 28px;
}
.bs-switch.thin {
    width: 46px !important;
    height: 20px !important;
}

.bs-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.bs-switch.compact{
  margin: 0 !important
}

/* The slider */
.bs-slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;
    cursor: pointer
}

input:disabled + .bs-slider {cursor: not-allowed !important}
input:disabled:checked + .bs-slider{ background-color: #ada !important }
input:disabled:not(:checked) + .bs-slider{ background-color: #eaeaea !important }

.bs-slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%
}
.bs-switch.thin .bs-slider:before {
    height: 12px !important;
    width: 12px !important;
}

input:checked + .bs-slider {
    background-color: #43A047;
}

input:focus + .bs-slider {
    box-shadow: 0 0 1px #43A047;
}

input:checked + .bs-slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.tabbable-hide{
  width: 0 !important;
  height: 0;
  opacity: 0;
  padding: 0;
  max-width: 0px;
}

.tms:not(.tabbable-hide){
  left: 1.5px;
}

.curr-preview{
  overflow: hidden
}

input.is-invalid + .curr-preview{
  border-color:#dc3545;
  padding-right:calc(1.5em + .75rem);
  background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat:no-repeat;
  background-position:center right calc(.375em + .1875rem);
  background-size:calc(.75em + .375rem) calc(.75em + .375rem)
}

/* Multiple select fields list*/
option[disabled]{
  display: none
}
.w-85 {
  width: 85% !important
}
.add-comm-button {
  position: absolute;
  margin-top: 0.25rem
}
select.narrow{
  padding-right: 1.25rem;
  background-position-x: right 3px
}

.btn-sm[disabled]{
  background-color: #999;
  border-color: #999;
  color: #fff;
}

.width-2{
  width: 50%
}

.fa-info-circle{
    cursor: pointer;
    color: #FF7010;
    text-decoration: none;
}
.fa-info-circle:hover{
    cursor: pointer;
    color: #FF9C29 !important;
}
</style>
<style>
.vti__input{
  font-size: 0.875rem
}
.vue-tel-input:not(.is-invalid) + p, .invisible{
  display: none
}
.auto-check-label-after{
  width: auto !important;
  padding-top: 2px;
  padding-bottom: 2px
}
.dp__input{
  display: block;
  width: 100%;
  font-weight: 400;
  line-height: 1.5 !important;
  background-clip: padding-box;
  border: 1px solid #ced4da !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: .25rem;
  min-height: calc(1.5em + .5rem + 2px);
  padding: .25rem .5rem .25rem 2.25rem !important;
  font-size: .875rem !important;
  border-radius: .2rem !important;
}
.dp__disabled{
  background-color: #e9ecef !important
}
</style>